<template>
  <div class="packageInfo" id="PackageInfo" v-loading.fullscreen="loading">
    <!-- Tracking number form -->
    <template v-if="isTrackingNumberAvailable">
      <BaseInput :fieldName="'trackingNumber'" :barcodeReaderType="isTrackingNumberBarcodeReaderAvailable"
        :ocrReaderType="isTrackingNumberOcrAvailable" :model="trackingNumber" :error="errorTrackingNumber"
        :label="trackingNumberName" :minLength="trackingNumberMinLength" :maxLength="trackingNumberMaxLength"
        v-model="trackingNumber_" />
    </template>
    <template v-if="isExternalIdAvailable">
      <BaseInput :barcodeReaderType="isExternalIdBarcodeReaderAvailable" :ocrReaderType="isExternalIdOcrAvailable"
        :fieldName="'externalId'" :model="externalId" :error="errorExternalId" :label="$t('externalId')"
        :minLength="externalIdMinLength" :maxLength="externalIdMaxLength" v-model="externalId_"
        @change="onExternalIdChanged" />
    </template>

    <!-- Custom form -->
    <FormBuilder :formSchema="selectedFormSchema" :formModel="formModel" :isHideSubmitButton="true"
      ref="customFieldsForm" @reviewCalculation="reviewCalculation" />

    <template v-if="isDimensionAvailable">
      <div class="title">{{ $t('Package size') }}</div>
      <hr class="line-break" />

      <!-- Products -->
      <div class="product-type" v-if="products.length">
        <label> {{ $t('Product Type') }} </label>
        <el-select class="d-block" v-model="selectedProductIndex" :placeholder="$t('Select Product')"
          @change="onSelectProduct">
          <el-option v-for="(item, index) in products" :key="item.code"
            :label="item.code ? `[${item.code}] ${item.name}` : item.name" :value="index" :model-value="index">
          </el-option>
        </el-select>
      </div>

      <!-- Dimension form -->
      <FormBuilder :formSchema="dimensionFormSchema" :formModel="dimension_"
        :skipValidation="shouldSkipValidateDemension()" justify="center" @submit="onFormSubmit" />
    </template>

    <!-- OK button -->
    <el-button id="PackageInfo_Submit" v-else circle type="primary" class="submit-button" @click="onFormSubmit">
      {{ $t('OK') }}
    </el-button>

    <ReviewCalculationDialog v-model:isShow="isShowCalculationDialog" :content="calculationContent" />
  </div>
</template>

<script lang="ts">
import BaseInput from '@/components/BaseInput.vue'
import ReviewCalculationDialog from '@/components/dialog/ReviewCalculationDialog.vue'
import FormBuilder from '@/components/FormBuilder.vue'
import CustomFieldsMixin from '@/components/mixins/CustomFieldsMixin.vue'
import { CLEAR_SCANNED_UPLOAD_STATUS, RESET_SCANNED_BARCODE_BLOB, SET_BARCODE_FORM_STEP } from '@/store/actions'
import { reviewCalculation } from '@/utils/api'
import errorHandler from '@/utils/errorHandler'
import mitt from '@/utils/mitt'
import { serverMappingValidate } from '@/utils/validator'
import { format, isValid, parse } from 'date-fns'
import isEqual from 'lodash/isEqual'
import { parseExternalId, TParsableField } from 'smartbarcode-web-core/src/bo/externalId'
import { DATE_TIME } from 'smartbarcode-web-core/src/utils/constants'
import { ECustomFieldType, EUserType } from 'smartbarcode-web-core/src/utils/enums/index'
import { isEmpty, isObjectValueEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IActivationFields,
  IBarcode,
  IDimension,
  IProduct,
  IProject,
  ITrackingNumber,
  IValidationRule,
  TError,
  TFormSchema,
} from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'
import { Prop, ProvideReactive, Watch } from 'vue-property-decorator'

@Options({
  components: {
    BaseInput,
    FormBuilder,
    ReviewCalculationDialog,
  },
  emits: ['update:dimension'],
  name: 'PackageInfoView',
})
export default class PackageInfoView extends mixins(CustomFieldsMixin) {
  @ProvideReactive() currentBarcodeType = ''
  @Prop({ type: String }) readonly barcodeType = ''
  @Prop({ type: String }) readonly trackingNumber?: string
  @Prop({ type: String }) readonly externalId?: string
  @Prop({ type: String }) readonly productId?: string = ''
  @Prop({ type: Object }) readonly dimension?: IDimension

  step = 4
  customFieldsModel: Record<string, unknown> = {}
  imagesFromOCR: Record<string, string[]> = {}
  temporaryFieldsOCRImages: Record<string, string[]> = {}
  loading = false

  isShowCalculationDialog = false
  calculationContent = ''

  readonly defaultDimension = { width: '', height: '', depth: '', weight: '' }

  dimensionFormSchema: TFormSchema = {}
  selectedTarget = ''
  trackingNumber_ = ''
  externalId_ = ''
  productId_ = ''
  dimension_: Record<string, string> = { ...this.defaultDimension }

  // Error validate tracking number
  errorTrackingNumber = ''
  errorExternalId = ''
  selectedProductIndex = 0

  get isExternalIdOcrAvailable() {
    return this.activationFields?.externalId?.ocrReaderType
  }

  get isExternalIdBarcodeReaderAvailable() {
    return this.activationFields?.externalId?.barcodeReaderType
  }

  get isTrackingNumberOcrAvailable() {
    return this.activationFields?.trackingNumber?.ocrReaderType
  }

  get isTrackingNumberBarcodeReaderAvailable() {
    return this.activationFields?.trackingNumber?.barcodeReaderType
  }

  get project(): IProject {
    return this.$store.state.project?.details
  }

  get barcode(): IBarcode {
    return this.$store.state.barcode?.barcode
  }

  get activationFields(): IActivationFields {
    return this.project.barcodeTypes[this.barcodeType]?.activationFields
  }

  get isTrackingNumberAvailable(): boolean {
    return this.activationFields?.trackingNumber?.isAvailable
  }

  get isExternalIdAvailable(): boolean {
    return this.activationFields?.externalId?.isAvailable
  }

  get isExternalIdMandatory(): boolean {
    return this.activationFields?.externalId?.isMandatory
  }

  get trackingNumberName(): string {
    return (
      this.project.barcodeTypes[this.barcodeType]?.activationFields?.trackingNumber?.label || this.$t('tracking_number')
    )
  }

  get trackingNumberDefault(): string {
    return this.project.barcodeTypes[this.barcodeType]?.activationFields?.trackingNumber?.default
  }

  async reviewCalculation(calculationFieldKey: string) {
    this.appendOCRImage()
    const reviewPayload = {
      field: 'activationData',
      customFieldKey: calculationFieldKey,
      barcode: {
        ...this.barcode,
        barcodeType: this.currentBarcodeType,
        activationData: {
          ...this.barcode.activationData,
          productId: this.productId_,
          ...(this.isTrackingNumberAvailable && {
            trackingNumber: this.trackingNumber_,
          }),
          ...(this.isExternalIdAvailable && {
            externalId: this.externalId_,
          }),
          ...(this.isDimensionAvailable && {
            dimension: this.dimension_,
          }),
          customFields: { ...this.generateCustomDataForRequest },
        },
      },
    }
    this.loading = true
    try {
      this.calculationContent = (await reviewCalculation(reviewPayload)).data
    } catch (e) {
      errorHandler(e as TError)
    }
    this.isShowCalculationDialog = true
    this.loading = false
  }

  get isTrackingNumberMandatory(): boolean {
    return this.activationFields?.trackingNumber?.isMandatory
  }

  get isDimensionAvailable(): boolean {
    return this.activationFields?.dimension?.isAvailable
  }

  get isDimensionMandatory(): boolean {
    return this.activationFields?.dimension?.isMandatory
  }

  get customForm() {
    const flattenActivateData = Object.keys(this.activationFields || {})
      .filter((key) => key.match(/^custom[a-zA-Z]*Fields/g))
      .reduce((acc, cur) => {
        return {
          ...acc,
          [cur]: this.activationFields[cur as keyof IActivationFields],
        }
      }, {})
    return flattenActivateData
  }

  get externalIdValidationRule(): ITrackingNumber {
    return this.activationFields.externalId
  }

  get externalIdMinLength(): number {
    return this.externalIdValidationRule.minLength
  }

  get externalIdMaxLength(): number {
    return this.externalIdValidationRule?.maxLength
  }

  get trackingNumberValidationRule(): ITrackingNumber {
    return this.activationFields.trackingNumber
  }

  get trackingNumberMinLength(): number {
    return this.trackingNumberValidationRule.minLength
  }

  get trackingNumberMaxLength(): number {
    return this.trackingNumberValidationRule?.maxLength
  }

  get isClient(): boolean {
    return this.$store.getters.userType === EUserType.CLIENT
  }

  get products() {
    const products = this.$store.state.project.products
    const product = products.find((e: IProduct) => e.id === this.barcode?.product?.id) || this.barcode?.product

    if (this.isClient && products.length) {
      return [{ code: '', name: this.$t('Direct Input') }, ...this.$store.state.project.products]
    }

    if (product && isEmpty(this.dimension)) {
      return [
        { code: '', name: this.$t('Direct Input') },
        { code: '', name: product.name },
      ]
    }

    return [{ code: '', name: this.$t('Direct Input') }]
  }

  get validateCustomFields() {
    return this.$refs.customFieldsForm?.validateAll()
  }

  get isAutoSaveOcrImageTrackingNumber(): string {
    return this.activationFields.trackingNumber?.ocrImageAutoSaveField || ''
  }

  get isAutoSaveOcrImageExternalId(): string {
    return this.activationFields.externalId?.ocrImageAutoSaveField || ''
  }

  onExternalIdChanged(externalId: string) {
    try {
      const parseExternalIdIfExist = () => {
        const customFields = this.activationFields.customFields
        if (!isEmpty(this.activationFields.externalId.parsers)) {
          const paths = (this.activationFields.externalId.parsers ?? []).map((s) => s.targetFieldPath)
          const parsableFields: TParsableField[] = [
            { key: 'trackingNumber', label: this.$t('tracking_number'), type: ECustomFieldType.TEXT, selections: {} },
            { key: 'dimension.width', label: this.$t('width'), type: ECustomFieldType.NUMBER, selections: {} },
            { key: 'dimension.weight', label: this.$t('weight'), type: ECustomFieldType.NUMBER, selections: {} },
            { key: 'dimension.height', label: this.$t('height'), type: ECustomFieldType.NUMBER, selections: {} },
            { key: 'dimension.depth', label: this.$t('depth'), type: ECustomFieldType.NUMBER, selections: {} },
            ...Object.entries(customFields).map(([k, v]) => ({
              key: `customFields.${k}`,
              label: v.label,
              type: v.fieldType,
              selections: { ...(!isEmpty(v.selections) && v.selections) },
            })),
          ].filter((f) => paths.includes(f.key)) as TParsableField[]

          Object.entries(parseExternalId(externalId, parsableFields, this.activationFields.externalId.parsers!)).forEach(
            ([k, v]) => {
              if (k.includes('customFields.')) {
                this.parseCustomFields(k, v)
              } else if (k.includes('dimension.')) {
                this.dimension_[k.replace(/dimension\./g, '')] = v as string
              } else {
                this.trackingNumber_ = v as string
              }
            }
          )
        }
      }

      parseExternalIdIfExist()
    } catch (error) {
      // Implement error handler
    }
  }

  parseCustomFields(k: string, v: string | string[]) {
    const customField = this.activationFields.customFields[k.replace(/customFields\./g, '')]
    try {
      if (customField.fieldType === ECustomFieldType.MULTI_SELECT || customField.fieldType === ECustomFieldType.SINGLE_SELECT) {
        const selections = customField.selections
        if (v.length > 0) {
          this.formModel[k.replace(/customFields\./g, '')] = v
          return
        }

        if (selections?.[v as string]) {
          this.formModel[k.replace(/customFields\./g, '')] = v
          return
        }
      }
      if (customField.fieldType === ECustomFieldType.DATE) {
        // Define the format of the input string
        // Define possible input formats
        const formats = ['yyyy/MM/dd', 'MM/dd/yyyy', 'MMMM d, yyyy', 'yyyy-MM-dd', 'MM-dd-yyyy', 'yyyyMMdd', 'MMddyyyy']

        let parsedDate

        for (const inputFormat of formats) {
          parsedDate = parse(v as string, inputFormat, new Date())

          // Check if the parsed date is valid
          if (isValid(parsedDate)) {
            break
          }
        }

        if (!isValid(parsedDate)) {
          throw new Error('Invalid date format')
        }
        const formattedDate = format(parsedDate as Date, 'yyyy-MM-dd')
        this.formModel[k.replace(/customFields\./g, '')] = formattedDate
        return
      }
    } catch (error) {
      // Implement error handler later
      return
    }
    this.formModel[k.replace(/customFields\./g, '')] = v
  }

  handleError() {
    if (this.barcode?.id) {
      this.$router.push({
        name: 'detail',
        params: {
          barcodeId: this.barcode?.id,
        },
      })
    } else {
      this.$router.push({
        name: 'home',
        params: {
          project: this.$store.getters.projectParam,
        },
      })
    }
  }

  shouldSkipValidateDemension() {
    // Need validate if is mandatory
    if (this.isDimensionMandatory) {
      return false
    }

    return isObjectValueEmpty(this.dimension_)
  }

  @Watch('trackingNumber_')
  onTrackingNumberChange() {
    this.clearError()
    this.validateTrackingNumber()
  }

  @Watch('externalId_')
  onExternalIdChange() {
    this.clearError()
    this.validateExternalId()
  }

  disableDimensionInput(disabled: boolean) {
    Object.entries(this.dimensionFormSchema).forEach(([key, value]) => {
      this.dimensionFormSchema[key] = {
        ...value,
        disabled,
      }
    })
  }

  onSelectProduct(value: number) {
    if (value > 0) {
      const products: IProduct[] = this.$store.state.project.products
      const barcodeDimension = this.barcode?.activationData?.dimension || {}
      const product = products.find((e: IProduct) => e.id === this.barcode?.product?.id) || this.barcode?.product
      if (isEmpty(barcodeDimension) && !isEmpty(product?.dimension)) {
        this.dimension_ = (product?.dimension as unknown) as Record<string, string>
        this.productId_ = product?.id || ''
        this.disableDimensionInput(true)
      } else {
        const products = this.$store.state.project.products
        this.dimension_ = { ...products[value - 1].dimension }
        this.productId_ = products[value - 1].id
        this.disableDimensionInput(true)
      }
    } else {
      this.dimension_ = { ...this.defaultDimension }
      this.productId_ = ''
      this.disableDimensionInput(false)
    }
  }

  onFormSubmit(): void {
    const isValidTrackingNumber = this.validateTrackingNumber()
    const isValidExternalId = this.validateExternalId()
    const isValidCustomFields = this.validateCustomFields

    if (!isValidTrackingNumber || !isValidCustomFields || !isValidExternalId) return

    return this.$refs.customFieldsForm?.performUpload(() => {
      this.appendOCRImage()
      this.$emit('update:dimension', {
        productId: this.productId_,
        ...(this.isTrackingNumberAvailable && {
          trackingNumber: this.trackingNumber_,
        }),
        ...(this.isExternalIdAvailable && {
          externalId: this.externalId_,
        }),
        ...(this.isDimensionAvailable && {
          dimension: this.dimension_,
        }),
        customFields: { ...this.generateCustomDataForRequest },
      })

      // Clear temporary data
      this.$store.commit(CLEAR_SCANNED_UPLOAD_STATUS)
      this.$store.commit(RESET_SCANNED_BARCODE_BLOB)
      this.imagesFromOCR = {}
      this.temporaryFieldsOCRImages = {}
    })
  }

  validateExternalId(): boolean {
    // If ExternalId is mandatory, allowNullOrEmpty should be false
    // It mean we have to validate it as required
    if (!this.isExternalIdAvailable) return true

    const validateRules: IValidationRule = {
      ...this.externalIdValidationRule,
      ...(this.isExternalIdMandatory ? { allowNullOrEmpty: false } : { allowNullOrEmpty: true }),
    }

    if (!validateRules) {
      this.clearError()
      return true
    }

    const field = this.$t('externalId')
    this.errorExternalId = serverMappingValidate(validateRules, field, this.externalId_) || ''
    return !this.errorExternalId
  }

  validateTrackingNumber(): boolean {
    // If tracking number is mandatory, allowNullOrEmpty should be false
    // It mean we have to validate it as required
    if (!this.isTrackingNumberAvailable) {
      return true
    }
    const validateRules: IValidationRule = {
      ...this.trackingNumberValidationRule,
      ...(this.isTrackingNumberMandatory ? { allowNullOrEmpty: false } : { allowNullOrEmpty: true }),
    }

    if (!validateRules) {
      this.clearError()
      return true
    }

    const field = this.$t('Tracking Number')
    this.errorTrackingNumber = serverMappingValidate(validateRules, field, this.trackingNumber_) || ''
    return !this.errorTrackingNumber
  }

  clearError() {
    this.errorTrackingNumber = ''
    this.errorExternalId = ''
  }

  created() {
    this.currentBarcodeType = this.barcodeType
    if (!this.barcodeType) {
      this.$router.replace({
        name: 'detail',
        params: {
          project: this.$store.getters.projectParam,
          barcodeId: this.$store.state.barcode.barcode.id,
        },
      })
      return
    }
    this.$store.commit(SET_BARCODE_FORM_STEP, this.step)

    if (!this.activationFields) {
      this.handleError()
    }

    if (
      !this.isDimensionAvailable &&
      !this.isTrackingNumberAvailable &&
      !this.isExternalIdAvailable &&
      isEmpty(this.customForm)
    ) {
      this.$emit('update:dimension')
    }

    // Dimension form
    if (this.isDimensionAvailable) {
      this.dimensionFormSchema = {
        width: {
          label: this.$t('width'),
          // This always true, mandatory should check for whole form
          // See shouldSkipValidate Dimension
          isMandatory: true,
          width: 8,
          mask: '#*.#*',
          inputType: 'decimal',
          disabled: false,
          isShowControlButton: false,
        },
        height: {
          label: this.$t('height'),
          isMandatory: true, // see width
          width: 8,
          mask: '#*.#*',
          inputType: 'decimal',
          disabled: false,
          isShowControlButton: false,
        },
        depth: {
          label: this.$t('depth'),
          isMandatory: true, // see width
          width: 8,
          mask: '#*.#*',
          inputType: 'decimal',
          disabled: false,
          isShowControlButton: false,
        },
        weight: {
          label: this.$t('weight'),
          isMandatory: true, // see width
          width: 8,
          mask: '#*.#*',
          inputType: 'decimal',
          disabled: false,
          isShowControlButton: false,
        },
      }
    }
    // products available
    if (this.productId) {
      const products: IProduct[] = this.$store.state.project.products
      const productDimension = this.barcode?.product?.dimension
      const barcodeDimension = this.barcode?.activationData?.dimension || {}
      this.productId_ = this.productId
      const product = products.find((e: IProduct) => e.id === this.barcode?.product?.id) || this.barcode?.product
      if (isEmpty(barcodeDimension) && !isEmpty(product?.dimension)) {
        this.selectedProductIndex = this.products.findIndex((e) => e.name === product?.name)
      } else {
        this.selectedProductIndex = isEqual(barcodeDimension, productDimension)
          ? products.findIndex((e: IProduct) => e.id === this.productId) + 1
          : 0
      }

      const dimension = {
        ...product?.dimension,
        ...barcodeDimension,
      } as IDimension
      if (dimension) {
        this.dimension_ = {
          width: `${dimension?.width || ''}`,
          height: `${dimension?.height || ''}`,
          depth: `${dimension?.depth || ''}`,
          weight: `${dimension?.weight || ''}`,
        }
      }
      this.disableDimensionInput(this.isClient || this.selectedProductIndex > 0)
    }
    if (this.dimension) {
      if (!this.productId) {
        this.dimension_ = {
          width: `${this.dimension.width || ''}`,
          height: `${this.dimension.height || ''}`,
          depth: `${this.dimension.depth || ''}`,
          weight: `${this.dimension.weight || ''}`,
        }
      }
    }

    // Generate model for custom fields
    this.generateCustomFormModel(this.barcode?.activationData)

    // set tracking number to field if available (Edit mode)
    if (this.isTrackingNumberAvailable) {
      this.trackingNumber_ = this.trackingNumber ?? this.trackingNumberDefault
    }
    // set externalId to field if available (Edit mode)
    if (this.isExternalIdAvailable) {
      this.externalId_ = this.externalId ?? ''
    }
  }

  appendOCRImage() {
    if (this.imagesFromOCR) {
      Object.keys(this.imagesFromOCR).forEach((key) => {
        this.formModel[key] = [...this.formModel?.[key], ...this.imagesFromOCR?.[key]]
      })
    }
  }

  mounted() {
    mitt.on('update:trackInfo', (event) => {
      this.formModel = Object.assign(this.formModel, event)
    })

    mitt.on('update:imageOCR', (event) => {
      this.saveOCRImage(event)
    })
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/mixins.scss';

.packageInfo {
  text-align: left;
}

.title {
  margin-top: 26px;
  line-height: 2;
}

hr.line-break {
  border-bottom: 1px solid #fff;
  margin: 5px 0 15px 0;
}

.product-type {
  margin: 16px 0;
}

.submit-button {
  display: block;
}
</style>
